import React from "react"
import { Link as Glink } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import schwalbeLogo from "../../content/assets/schwalbe-logo.png"
import konaLogo from "../../content/assets/kona-logo.png"
import demaLogo from "../../content/assets/dema-logo.png"
import superiorLogo from "../../content/assets/superior-logo.png"
import dragLogo from "../../content/assets/drag-logo.png"
import beflyLogo from "../../content/assets/befly-logo.png"
import ergotecLogo from "../../content/assets/ergotec-logo.png"
import toneLogo from "../../content/assets/tone-logo.png"
import xlcLogo from "../../content/assets/xlc-logo.png"
import extendLogo from "../../content/assets/extend-logo.png"
import maguraLogo from "../../content/assets/magura-logo.png"
import kendaLogo from "../../content/assets/kenda-logo.png"
import shimanoLogo from "../../content/assets/shimano-logo.png"
import sramLogo from "../../content/assets/sram-logo.png"
import p2rLogo from "../../content/assets/p2r-logo.png"
import selleroyalLogo from "../../content/assets/selleroyal-logo.png"
import sellesmpLogo from "../../content/assets/sellesmp-logo.png"
import fsaLogo from "../../content/assets/fsa-logo.png"
import continentalLogo from "../../content/assets/continental-logo.png"
import smithLogo from "../../content/assets/smith-logo.png"
import cratoniLogo from "../../content/assets/cratoni-logo.png"
import fiveglovesLogo from "../../content/assets/five-logo.png"
import northwaveLogo from "../../content/assets/northwave-logo.png"
import mucoffLogo from "../../content/assets/mucoff-logo.png"
import zefalLogo from "../../content/assets/zefal-logo.png"
import isostarLogo from "../../content/assets/isostar-logo.png"
import sksLogo from "../../content/assets/sks-logo.png"
import hamaxLogo from "../../content/assets/hamax-logo.png"
import rockshoxLogo from "../../content/assets/rockshox-logo.png"
import sigmaLogo from "../../content/assets/sigma-logo.png"
import onguardLogo from "../../content/assets/onguard-logo.png"
import cogFilled from "../../content/assets/cog-filled.svg"

const localeNumberOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const useStyles = makeStyles(theme => ({
  mainGrid: {
    placeSelf: "stretch",
  },
  card: {
    position: "relative",
    borderRadius: 2,
    margin: 5,
    boxShadow: "0px 0px 6px rgba(0,0,0,.2)",
    transition: "all .2s ease-in-out",
    backgroundColor: "#fff",
    width: "100%",
    "&:hover": {
      boxShadow: "0px 0px 8px rgba(0,0,0,.8)",
    },
    [theme.breakpoints.up("md")]: {
      margin: 9,
    },
  },
  cardActionArea: {
    display: "contents",
    height: "100%",
    alignItems: "baseline",
  },
  cardLink: {
    textDecoration: "none",
    display: "inline-flex",
    height: "100%",
    width: "100%",
  },
  cardContentGrid: {
    padding: "5px",
  },
  media: {
    height: "25vh",
    backgroundSize: "contain",
    opacity: 1,
    marginTop: "5vh",
    marginBottom: "5vh",
    "&:hover": {
      opacity: 0,
    },
  },
  mediaImageOne: {
    position: "absolute",
    height: "25vh",
    width: "100%",
    backgroundSize: "contain",
    top: 0,
    left: 0,
    opacity: 0,
    transition: "all 0.2s ease-in-out",
    marginTop: "5vh",
    marginBottom: "5vh",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#fff",
    },
  },
  mediaNoImageOne: {
    position: "absolute",
    height: "25vh",
    width: "100%",
    backgroundSize: "contain",
    top: 0,
    left: 0,
    opacity: 0,
    marginTop: "5vh",
    marginBottom: "5vh",
    "&:hover": {
      opacity: 1,
    },
  },
  overlay: {
    position: "absolute",
    top: "3%",
    right: "3%",
    color: "#fff",
    background: `url(${cogFilled})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "2px",
    width: "6vh",
    height: "6vh",
    fontSize: "1.8vh",
    fontWeight: "700",
    // boxShadow:
    //   "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "rotate(-12deg)",
    },
  },
  overlayEmblem: {
    position: "absolute",
    top: "3%",
    left: "3%",
    color: "#fff",
    backgroundColor: "#e30202",
    borderRadius: "2px",
    width: "auto",
    paddingLeft: "2%",
    paddingRight: "2%",
    height: "30px",
    fontSize: "0.9rem",
    fontWeight: "600",
    textTransform: "uppercase",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  prices: {
    fontSize: "2.2vh",
    fontWeight: "bold",
    paddingBottom: "2%",
  },
  priceKN: {
    color: "#6b6b6b",
    fontSize: "12px",
  },
  pricebef: {
    textDecoration: "line-through",
    fontSize: "0.8rem",
    color: "#808080",
  },
  recommendedPriceText: {
    color: "#808080",
    fontSize: "10px",
  },
  recommendedPriceNumber: {
    color: "#808080",
    fontSize: "10px",
  },
  availableGrid: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingBottom: "2%",
    marginBottom: "2%",
  },
  sizesHeading: {
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "0.1vh",
    margin: "0",
  },
  sizesMainDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
  sizeDiv: {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #a1a1a1",
    borderRadius: "2px",
    margin: "1%",
    marginLeft: "2%",
    marginRight: "2%",
    textAlign: "center",
    padding: "2%",
    boxShadow: "0px 0px 1px rgba(0,0,0,.5)",
  },
}))

export default function ProductItem(props) {
  const classes = useStyles()
  const {
    title,
    price,
    priceBefore,
    image,
    image1,
    slug,
    emblem,
    specialDeal,
    brand,
    available,
  } = props

  return (
    <Grid item xs={12} sm={4} md={3} key={slug} className={classes.mainGrid}>
      <Glink to={slug} className={classes.cardLink}>
        <Card className={classes.card}>
          <CardActionArea className={classes.cardActionArea}>
            <CardMedia className={classes.media} image={image} title={title} />
            {image1 && (
              <CardMedia
                className={classes.mediaImageOne}
                image={image1}
                title={title}
              />
            )}
            {!image1 && (
              <CardMedia
                className={classes.mediaNoImageOne}
                image={image}
                title={title}
              />
            )}
            {priceBefore && !specialDeal && (
              <span className={classes.overlay}>
                {Math.round(((price - priceBefore) / priceBefore) * -100)}%
              </span>
            )}
            {priceBefore && specialDeal && (
              <span className={classes.overlay}>
                {Math.round(
                  ((((100 - specialDeal) / 100) * price - priceBefore) /
                    priceBefore) *
                    -100
                )}
                %
              </span>
            )}
            {emblem && (
              <span className={classes.overlayEmblem}>
                <p>{emblem}</p>
              </span>
            )}
            {brand === "schwalbe" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={schwalbeLogo}
                  alt="Schwalbe Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "kona" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={konaLogo}
                  alt="Kona Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "dema" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={demaLogo}
                  alt="Dema Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "superior" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={superiorLogo}
                  alt="Superior Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "drag" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={dragLogo}
                  alt="Drag Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "befly" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={beflyLogo}
                  alt="Befly Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "ergotec" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={ergotecLogo}
                  alt="Ergotec Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "t-one" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={toneLogo}
                  alt="T-One Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "extend" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={extendLogo}
                  alt="Extend Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "xlc" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={xlcLogo}
                  alt="XLC Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "magura" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={maguraLogo}
                  alt="Magura Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "kenda" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={kendaLogo}
                  alt="Kenda Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "shimano" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={shimanoLogo}
                  alt="Shimano Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "sram" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={sramLogo}
                  alt="Sram Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "p2r" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={p2rLogo}
                  alt="P2R Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "selleroyal" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={selleroyalLogo}
                  alt="Selle Royal Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "sellesmp" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={sellesmpLogo}
                  alt="Selle SMP Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "fsa" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={fsaLogo}
                  alt="FSA Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "continental" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={continentalLogo}
                  alt="Continental Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "smith" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={smithLogo}
                  alt="Smith Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "cratoni" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={cratoniLogo}
                  alt="Cratoni Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "five" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={fiveglovesLogo}
                  alt="Five Gloves Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "northwave" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={northwaveLogo}
                  alt="Northwave Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "mucoff" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={mucoffLogo}
                  alt="MucOff Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "zefal" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={zefalLogo}
                  alt="Zefal Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "isostar" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={isostarLogo}
                  alt="Isostar Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "sks" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={sksLogo}
                  alt="SKS Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "hamax" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={hamaxLogo}
                  alt="Hamax Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "rockshox" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={rockshoxLogo}
                  alt="RockShox Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "sigma" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={sigmaLogo}
                  alt="Sigma Sport Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            {brand === "onguard" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={onguardLogo}
                  alt="Onguard Brand"
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
            )}
            <CardContent className={classes.cardContentGrid}>
              <Typography variant="body1" component="h2" align="center">
                {title}
              </Typography>
              {priceBefore && (
                <>
                  <Typography
                    align="center"
                    className={classes.recommendedPriceNumber}
                    variant="subtitle2"
                  >
                    PMPC:{" "}
                    <strike>
                      {priceBefore.toLocaleString("hr-HR", localeNumberOptions)}{" "}
                      €
                    </strike>
                  </Typography>
                </>
              )}
              {!specialDeal && (
                <Typography
                  className={classes.prices}
                  variant="body1"
                  color="textPrimary"
                  component="p"
                  align="center"
                >
                  {/* <Typography
                className={classes.pricebef}
                align="center"
                variant="subtitle2"
              >
                {priceBefore && (
                <span>{priceBefore.toLocaleString("hr-HR", localeNumberOptions)} €</span>)}</Typography> */}
                  {price.toLocaleString("hr-HR", localeNumberOptions)} €
                </Typography>
              )}
              {specialDeal && (
                <Typography
                  className={classes.prices}
                  variant="body1"
                  color="textPrimary"
                  component="p"
                  align="center"
                >
                  {Math.abs(((100 - specialDeal) / 100) * price).toLocaleString(
                    "hr-HR",
                    localeNumberOptions
                  )}{" "}
                  €
                </Typography>
              )}
              {/* {!specialDeal && (
                <Typography
                  className={classes.priceKN}
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  align="center"
                >
                  {Math.abs(price * 7.5345).toLocaleString(
                    "hr-HR",
                    localeNumberOptions
                  )}{" "}
                  kn
                </Typography>
              )} */}
              {/* {specialDeal && (
                <Typography
                  className={classes.priceKN}
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  align="center"
                >
                  {Math.abs(
                    ((100 - specialDeal) / 100) * price * 7.5345
                  ).toLocaleString("hr-HR", localeNumberOptions)}{" "}
                  kn
                </Typography>
              )} */}
              {available && available.length && (
                <Grid item xs={12} md={12} className={classes.availableGrid}>
                  <Grid item xs={12} md={12}>
                    <p className={classes.sizesHeading}>Dostupno na lageru:</p>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={classes.sizesMainDiv}>
                      {available.map(s => (
                        <td className={classes.sizeDiv}>{s}</td>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </Glink>
    </Grid>
  )
}
