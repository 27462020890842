import React from "react"
import { Link as Glink } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, Grid, Typography, Link, Container } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    alignContent: "stretch",
    overflow: "hidden",
    maxWidth: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  containerMain: {
    display: "grid",
    padding: 0,
    overflow: "hidden",
    position: "relative",
    borderRadius: "5px",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        boxShadow: "0px 0px 6px rgba(0,0,0,.2)",
      },
    },
  },
  mainFeaturedPost: {
    minHeight: "40vh",
    position: "relative",
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    // borderRadius: "5px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    overflow: "hidden",
    transform: "scale(1.14)",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      minHeight: "40vh",
      filter: "brightness(.9)",
      "&:hover": {
        filter: "brightness(1.1)",
      },
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.0)",
  },
  mainFeaturedPostContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(1),
    fontVariant: "all-small-caps",
    width: "100%",
    // borderRadius: "5px",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
  },
  mainFeaturedPostLink: {
    padding: theme.spacing(0),
    flexShrink: 0,
  },
  wrap: {
    whiteSpace: "normal",
    textAlign: "center",
    // textShadow: "0px 0px 5px #000",
    fontSize: "40px",
    backgroundColor: "rgba(0,0,0,.6)",
    padding: "1%",
    fontWeight: 800,
    fontFamily: "Roboto",
    [theme.breakpoints.up("md")]: {
      fontSize: "45px",
    },
  },
}))

export default function PageItem(props) {
  const classes = useStyles()
  const {
    title,
    //description,
    image,
    slug,
  } = props

  return (
    <Grid item xs={12} sm={6} lg={3} className={classes.root}>
      <Container className={classes.containerMain}>
        <Paper
          className={classes.mainFeaturedPost}
          style={{ backgroundImage: `url(${image})` }}
        >
          <Link
            component={Glink}
            to={slug}
            color="inherit"
            noWrap
            variant="body2"
            underline="none"
          >
            {<img style={{ display: "none" }} src={image} alt={title} />}
            <div className={classes.overlay} />
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography
                    component="h2"
                    color="inherit"
                    className={classes.wrap}
                  >
                    {title}
                  </Typography>
                  {/* <Typography
                  variant="h5"
                  color="inherit"
                  paragraph
                  className={classes.wrap}
                >
                  {description}
                </Typography> */}
                </div>
              </Grid>
            </Grid>
          </Link>
        </Paper>
      </Container>
    </Grid>
  )
}
