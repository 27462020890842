import React from "react"
import Carousel from "react-material-ui-carousel"
import Item from "./Item"
import slider from "../assets/slider.js"
import "../styles/Carousel.css"

export default function ImageCarousel() {
  return (
    <Carousel
      className="crsl"
      next={(next, active) =>
        console.log(`we left ${active}, and are now at ${next}`)
      }
      prev={(prev, active) =>
        console.log(`we left ${active}, and are now at ${prev}`)
      }
      swipe={true}
      indicators={false}
      navButtonsAlwaysVisible={true}
      navButtonsProps={{
        style: {
          backgroundColor: "rgba(0,0,0,.2)",
        },
      }}
      stopAutoPlayOnHover={false}
      autoPlay={true}
      interval={5000}
      animation="fade"
      timeout={{ appear: 500, enter: 500, exit: 0 }}
      fullHeightHover={false}
    >
      {slider.map(item => (
        <Item className="itm" key={item.id} item={item} />
      ))}
    </Carousel>
  )
}
