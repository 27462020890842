import React from "react"
import { Paper } from "@material-ui/core"
import "../styles/Item.css"

function Item({ item }) {
  return (
    <Paper square className="paper">
      <span>
        <a href={item.href} rel="noreferrer">
          <img src={item.image} alt={item.title} className="image"></img>
        </a>
      </span>
      <div className="div-container">
        <h2 className="h2">{item.title}</h2>
        <p className="p">{item.description}</p>
        <a href={item.href} rel="noreferrer">
          <div className="button">{item.button}</div>
        </a>
      </div>
    </Paper>
  )
}

export default Item
